import { GET_USER_NAME } from '../../constants/ActionTypes';

const initialState = {
  userNames: {},
  error: null,
};

export default function userfullname(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_NAME + '_SUCCESS':
      console.log('Reducer: GET_USER_NAME_SUCCESS action received', action);
      const newState = {
        ...state,
        userNames: {
          ...state.userNames,
          [action.result.id]: action.result.fullname,
        },
        error: null,
      };
      console.log('Reducer: New state after GET_USER_NAME_SUCCESS', newState);
      return newState;
    case GET_USER_NAME + '_FAILURE':
      console.log('Reducer: GET_USER_NAME_FAILURE action received', action);
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
