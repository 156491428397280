import React, { useCallback, useRef, useEffect, useLayoutEffect } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  Handle,
  Position,
} from 'reactflow';
import 'reactflow/dist/style.css';

// Instead of importing nanoid, we'll create a simple ID generator
const generateId = () => Math.random().toString(36).substr(2, 9);

const MindMapNode = ({ data, isConnectable }) => {
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${data.label.length * 8}px`;
    }
  }, [data.label]);

  useEffect(() => {
    if (data.isNew) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 1);
    }
  }, [data.isNew]);

  return (
    <>
      <div className="mindmap-node">
        <div className="dragHandle">
          <svg viewBox="0 0 24 24">
            <path
              fill="#333"
              stroke="#333"
              strokeWidth="1"
              d="M15 5h2V3h-2v2zM7 5h2V3H7v2zm8 8h2v-2h-2v2zm-8 0h2v-2H7v2zm8 8h2v-2h-2v2zm-8 0h2v-2H7v2z"
            />
          </svg>
        </div>
        <input
          ref={inputRef}
          value={data.label}
          onChange={(evt) => data.onChange(evt.target.value)}
          className="nodrag"
        />
        {data.url && (
          <a
            href={data.url}
            className="node-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            🔗
          </a>
        )}
      </div>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </>
  );
};

const MindMapBlock = ({ data, onChangeBlock, block, mode }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(data.nodes || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(data.edges || []);
  const initialNodes = [
    { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
    { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
  ];
  const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges],
  );

  const onNodeLabelChange = useCallback(
    (nodeId, newLabel) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            return { ...node, data: { ...node.data, label: newLabel } };
          }
          return node;
        }),
      );
    },
    [setNodes],
  );

  const onNodeDoubleClick = useCallback(
    (event, node) => {
      const newUrl = prompt('Enter URL for this node:', node.data.url || '');
      if (newUrl !== null) {
        setNodes((nds) =>
          nds.map((n) => {
            if (n.id === node.id) {
              return { ...n, data: { ...n.data, url: newUrl } };
            }
            return n;
          }),
        );
      }
    },
    [setNodes],
  );

  const onPaneClick = useCallback(
    (event) => {
      const newNode = {
        id: generateId(),
        type: 'mindmap',
        position: { x: event.clientX, y: event.clientY },
        data: {
          label: 'New Node',
          isNew: true,
          onChange: (newLabel) => onNodeLabelChange(newNode.id, newLabel),
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [setNodes, onNodeLabelChange],
  );

  useEffect(() => {
    if (mode === 'edit') {
      onChangeBlock(block, { ...data, nodes, edges });
    }
  }, [nodes, edges, mode, block, data, onChangeBlock]);

  return (
    <div style={{ height: '500px', border: '1px solid #ccc' }}>
      <ReactFlow
        nodes={initialNodes}
        edges={initialEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onPaneClick={mode === 'edit' ? onPaneClick : undefined}
        onNodeDoubleClick={mode === 'edit' ? onNodeDoubleClick : undefined}
        nodeTypes={{ mindmap: MindMapNode }}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default MindMapBlock;
