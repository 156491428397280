/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */
// User actions
export const GET_USER_NAME = 'GET_USER_NAME';
export const GET_USER_NAME_SUCCESS = 'GET_USER_NAME_SUCCESS';
export const GET_USER_NAME_FAILURE = 'GET_USER_NAME_FAILURE';
