import { GET_USER_NAME } from '../constants/ActionTypes';

export function getUserName(userId) {
  console.log('getUserName action called with userId:', userId);
  return {
    type: GET_USER_NAME,
    request: {
      op: 'get',
      path: `/++api++/@user-name/${userId}`,
    },
  };
}

export function getUserNameSuccess(result) {
  console.log('getUserNameSuccess action called with result:', result);
  return {
    type: GET_USER_NAME + '_SUCCESS',
    result: {
      id: result.id,
      fullname: result.fullname || result.id
    },
  };
}

export function getUserNameFailure(error) {
  console.error('getUserNameFailure:', error);
  return {
    type: GET_USER_NAME + '_FAILURE',
    error: error.message || 'An error occurred while fetching user names',
  };
}
